<template>
  <div class="row user-settings px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <h2 class="main-title">
          Send Reports
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card p-60px">
          <div class="header-settings mx-0 mt-0"></div>
          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>

          <div class="list-table-container" v-if="loading == false">
            <table class="table">
              <thead>
                <tr>
                  <th class="w-50px">
                    <input type="checkbox" name="" id="" v-model="selectAll" />
                  </th>
                  <th>Report Type</th>
                  <th>Last Sent On</th>
                  <th>Last Sent By</th>
                  <th>Send Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in data_users" :key="index">
                  <td class="w-50px">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      :value="item.type"
                      v-model="selected"
                    />
                  </td>
                  <td>
                    {{ item.label }}
                  </td>
                  <td>{{ item.sent }}</td>
                  <td>{{ item.sender }}</td>
                  <td>{{ item.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-3">
            <button
              type="submit"
              class="btn-blue float-right"
              @click.prevent="sendReports()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_report"],
  data() {
    return {
      data_users: [],
      loading: true,
      height_card: "",
      selected: []
    };
  },
  async created() {
    const self = this;
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      }
    } else {
      this.$router.push("/");
    }

    self.getData();
    //GET THE COST CENTRE DATA AND SAVE IT IN [manager_centres]
  },
  methods: {
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    changeIDUser(id) {
      this.$emit("changeIDUser", id);
    },
    async getData() {
      const self = this;
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${self.id_report}/send`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.data_users = response.data;
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    sendReports() {
      const self = this;
      this.loading = true;

      let element = {};
      self.selected.forEach(function(recomendation) {
        switch (recomendation) {
          case "send_cost_centre":
            element.send_cost_centre = true;
            break;
          case "send_user_bill":
            element.send_user_bill = true;
            break;
          case "send_sms_bill":
            element.send_sms_bill = true;
            break;
          case "send_manager":
            element.send_manager = true;
            break;
          case "send_master":
            element.send_master = true;
            break;
        }
      });
      this.axios({
        method: "post",
        url: `https://api.expertel.ca/api/v1/client/me/reports/${self.id_report}/send`,
        responseType: "json",
        //contentType: 'application/json',
        data: element,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          if (response.data.status == false) {
            self.makeToast("ERROR", "There was an error", "danger");
            self.loading = false;
          } else {
            self.makeToast("Ok", "Reports sent successfully", "success");
            self.getData();
            self.name = "";
            self.email = "";
            self.loading = false;
          }
        })
        .catch(
          error => self.makeToast("ERROR", error, "danger"),
          (self.loading = false)
        );
    }
  },
  computed: {
    selectAll: {
      get: function() {
        return this.data_users.type
          ? this.selected.length == this.data_users.length
          : false;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          this.data_users.forEach(function(recomendation) {
            //console.log(recomendation);
            selected.push(recomendation.type);
          });
        }

        this.selected = selected;
      }
    }
  }
};
</script>
